<template>
  <v-container fluid class="fill-height border ma-0 pa-0">
    <qrcode-stream
        @detect="onDetect"
        :torch="true"

        class="rounded-lg w-75"
        @init="onInit"
    >
    </qrcode-stream>


  </v-container>
</template>

<script>
export default {
  name: "StandardQrScannerComponent",
  data: () => ({
    scanned_url: "",
    is_error: false,
    message: ""
  }),
  methods: {
    async onDetect(promise) {
      try {
        const {
          content // decoded String
        } = await promise;
        let reg = new RegExp(/(^http[s]?:\/{2})|(^www)|(^\/{1,2})/igm);
        if (reg.test(content)) {
          let a = document.createElement('a');
          a.href = content;
          a.click();
          a.remove();
        }

      } catch (error) {
        this.$forceUpdate();
      }
    },
    async onInit(promise) {
      // show loading indicator

      try {
        const {capabilities} = await promise;

        console.log(capabilities);
        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          alert("NotAllowedError");
        } else if (error.name === "NotFoundError") {
          alert("NotFoundError");
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
          alert("NotSupportedError");
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
          alert("NotReadableError");
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
          alert("OverconstrainedError");
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
          alert("OverconstrainedError");
        }
      } finally {
        // hide loading indicator
      }
    }
  }
};
</script>

<style scoped>
#close_btn {
  position: absolute;
  min-width: 40px !important;
  min-height: 40px !important;
  top: 10px;
  right: 10px;
  z-index: 4000;
}

#scanned_url {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: auto;
  z-index: 4000;
  background: white;
}
</style>
