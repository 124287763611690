import Vue from 'vue'
import App from './App.vue'

import vuetify from "@/plugins/vuetify/index";


//register qr scanner globally
import VueQrcodeReader from "vue-qrcode-reader";

Vue.use(VueQrcodeReader);

Vue.config.productionTip = false

new Vue({
    vuetify,
    render: h => h(App),
}).$mount('#app')
