<template>
  <v-app class="pa-0 ma-0">
    <standard-qr-scanner-component/>
  </v-app>
</template>

<script>


import StandardQrScannerComponent from "@/components/StandardQrScannerComponent";

export default {
  name: 'App',
  components: {
    StandardQrScannerComponent

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 !important;
}

body{
  margin: 0 !important;
}
</style>
