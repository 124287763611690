import '@mdi/font/css/materialdesignicons.css'
import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

const vuetify = new Vuetify({
    icons: {
        iconfont: "mdi" || "md" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        themes: {
            light: {
                primary: {
                    base: "#F17800",
                    lighten3: "#FFBB78"
                },
                secondary: "#F4E9E3",
                tertiary: {
                    base: "#DFDFDB",
                    lighten3: "#EFEFEE"
                },
                accent: "#546BBE",
                bg: "#F8F4F1"
            }
        }
    }
});

export default vuetify;
